<template>
    <TopNav></TopNav>
		<h1>Desi Hesaplama</h1>
		<fieldset>
      <legend>Desi Hesaplama Aracı</legend>
      <label for="uzunluk" class="leftLabel">Uzunluk:</label> <input type="number" v-model="inputUzunluk" id="uzunluk" min="0"> <label for="uzunluk"><abbr title="santimetre">cm</abbr></label>
      <br>
      <label for="genislik" class="leftLabel">Genişlik:</label> <input type="number" v-model="inputGenislik" id="genislik" min="0"> <label for="genislik"><abbr title="santimetre">cm</abbr></label>
      <br>
      <label for="yukseklik" class="leftLabel">Yükseklik:</label> <input type="number" v-model="inputYukseklik" id="yukseklik" min="0"> <label for="yukseklik"><abbr title="santimetre">cm</abbr></label>
      <br><br>
      Desi: <span class="blue">{{ desi() }}</span>
		</fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Desi",
  data() {
    return {
      inputUzunluk: 0,
      inputGenislik: 0,
      inputYukseklik: 0
    }
  },
  components: {
    TopNav
  },
  methods: {
    desi: function () {
      let number = ((this.inputUzunluk * this.inputGenislik * this.inputYukseklik) / 3000).toFixed(2);
      let desi = Number(number);
      return desi.toLocaleString('tr-TR');
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label.leftLabel {
  display: inline-block;
  min-width: 120px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
